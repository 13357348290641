import EngineService from '../core/engine-service';

const API_ENDPOINT = '/users';

export function getUsers(params = {}) {
  return EngineService.get(API_ENDPOINT, params).then((json) => json);
}

export function getUser(userId, params) {
  return EngineService.get(`${API_ENDPOINT}/${userId}`, params).then((json) => json);
}

export function createUser(data) {
  return EngineService.post(`${API_ENDPOINT}`, {
    user: data,
  }).then((json) => json.user);
}

export function updateUser(userId, data) {
  return EngineService.patch(`${API_ENDPOINT}/${userId}`, {
    user: data,
  }).then((json) => json.user);
}

export function reassignUserResource(fromUserId, toUserId) {
  return EngineService.post(`${API_ENDPOINT}/${fromUserId}/transfers`, {
    transfer: {
      to_user_id: toUserId,
    },
  }).then((json) => json);
}

export function updateTwilioProfile(userId, payload) {
  return EngineService.patch(`${API_ENDPOINT}/${userId}`, {
    user: {
      twilio_profile: payload,
    },
  }).then((json) => json);
}

export function deleteUserToken(userId, tokenId) {
  return EngineService.delete(`${API_ENDPOINT}/${userId}/tokens/${tokenId}`).then((json) => json);
}

export function getUsersFilterProperties(params = {}) {
  return EngineService.get('/filters/users/columns', params).then((json) => json);
}

export function loginAsSomeoneElse(userId) {
  return EngineService.post(`${API_ENDPOINT}/${userId}/admin_login`, null, null, null, {
    withCredentials: true,
  }).then((json) => json);
}

export default {
  getUser,
  createUser,
  updateUser,
};
